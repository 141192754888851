<script>
import FormsValidatedInput from "@/components/Forms/ValidatedInput.vue";
import IncidentTable from "@/components/Incident/IncidentTable.vue";
import LoanStatus from "@/components/Loan/Status/LoanStatusBadge.vue";
import AvailabilityStatus from "@/components/Loanable/AvailabilityStatus.vue";
import LoanableCalendar from "@/components/Loanable/Calendar.vue";
import LoanableSidebar from "@/components/Loanable/LoanableSidebar.vue";
import IconButton from "@/components/shared/IconButton.vue";
import PaginatedTable from "@/components/shared/PaginatedTable.vue";
import { Column } from "@/components/shared/PaginatedTableColumns";
import { capitalize } from "@/helpers/filters";
import {
  canDeleteLoanable,
  canEditLoanable,
  isAdminOfLoanable,
  isCoownerOrOwner,
} from "@/helpers/permissions/loanables";

import { get, post } from "@/requests/server";
import dayjs from "dayjs";
import Vue from "vue";

export default Vue.extend({
  name: "LoanableOverview",
  components: {
    AvailabilityStatus,
    FormsValidatedInput,
    IconButton,
    IncidentTable,
    LoanableCalendar,
    LoanStatus,
    PaginatedTable,
    LoanableSidebar,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      loaded: false,
      loading: false,
      loadingAvailabilities: false,
      loanable: null,
      newIncident: {},
      calendarLoading: false,
      events: [],
      loanColumns: [
        new Column("id", "ID", "id", { showByDefault: false }),
        new Column("departure_at", "Départ", "date", {
          formatter: (value, key, item) =>
            dayjs.atTz(value, item.loanable.timezone).format("D MMMM YYYY HH:mm"),
        }),
        new Column("actual_return_at", "Retour", "date", {
          showByDefault: false,
          formatter: (value, key, item) =>
            dayjs.atTz(value, item.loanable.timezone).format("D MMMM YYYY HH:mm"),
        }),
        Column.withRelation(
          "borrower_user.full_name",
          "Emprunteur",
          {
            relation: "users",
            label: "full_name",
            field: "id",
            params: { for: "profile" },
          },
          "borrower_user.id"
        ),
        Column.withRelation(
          "community.name",
          "Communauté",
          { relation: "communities", label: "name", field: "id", params: { for: "admin" } },
          "community_id",
          { showByDefault: false }
        ),
        Column.withSelect("status", "Statut", [
          { value: null, label: "Tous" },
          { value: "requested", label: this.$t("loans.statuses.requested.label") },
          { value: "accepted", label: this.$t("loans.statuses.accepted.label") },
          { value: "confirmed", label: this.$t("loans.statuses.confirmed.label") },
          { value: "ongoing", label: this.$t("loans.statuses.ongoing.label") },
          { value: "ended", label: this.$t("loans.statuses.ended.label") },
          { value: "validated", label: this.$t("loans.statuses.validated.label") },
          { value: "completed", label: this.$t("loans.statuses.completed.label") },
          { value: "rejected", label: this.$t("loans.statuses.rejected.label") },
          { value: "canceled", label: this.$t("loans.statuses.canceled.label") },
        ]),
        new Column("duration_in_minutes", "Durée (minutes)", "number", {
          showByDefault: false,
        }),

        Column.withSelect(
          "is_self_service",
          capitalize(this.$t("loans.self_service.is_self_service")),
          [
            { value: null, label: capitalize(this.$t("loans.self_service.all")) },
            { value: true, label: capitalize(this.$t("loans.self_service.is_self_service")) },
            { value: false, label: capitalize(this.$t("loans.self_service.on_demand")) },
          ],
          { showByDefault: false }
        ),
      ],
    };
  },
  computed: {
    user() {
      return this.$store.state.user;
    },
    isAdminOfLoanable() {
      return this.loanable && isAdminOfLoanable(this.user, this.loanable);
    },
    isAdminOrCoowner() {
      return (
        this.loanable && (this.isAdminOfLoanable || isCoownerOrOwner(this.user, this.loanable))
      );
    },
    canEditLoanable() {
      return canEditLoanable(this.user, this.loanable);
    },
    canDeleteLoanable() {
      return canDeleteLoanable(this.user, this.loanable);
    },
    hasAnyBlockingActiveIncidents() {
      return (
        this.loanable.active_incidents &&
        this.loanable.active_incidents.filter((i) => i.is_blocking).length > 0
      );
    },
    filtersIncidentId() {
      return "incident.id" in this.$route.query;
    },
  },
  beforeMount() {
    this.load();
  },
  methods: {
    async load() {
      this.loading = true;
      const { data } = await get(`/loanables/${this.id}?ressource=true`, {
        cleanupCallback: () => (this.loading = false),
        notifications: { action: "chargement du véhicule" },
      });
      this.loanable = data;
      this.loaded = true;
    },

    async calendarChanged({ view, startDate, endDate }) {
      // Load 1 extra month/week/day on both side for smoother navigation
      let start = dayjs(startDate).subtract(1, view);
      let end = dayjs(endDate).add(1, view);

      this.loadingAvailabilities = true;
      const response = await get(`/loanables/${this.id}/events`, {
        axiosRequestConfig: {
          params: {
            start: start.format("YYYY-MM-DD HH:mm:ss"),
            end: end.format("YYYY-MM-DD HH:mm:ss"),
            responseMode: "available",
          },
        },
        notifications: { action: "calcul des disponibilités de ce véhicule" },
        requestOptions: { cancelId: `loanables/${this.id}/events` },
        cleanupCallback: () => (this.loadingAvailabilities = false),
      });
      this.events = response.data.map((e) => {
        if (e.type === "loan") {
          return {
            ...e,
            title: e.data.borrower_name ?? "Emprunt",
            class: [`loan_id_${e.data.loan_id}`],
          };
        } else {
          return {
            ...e,
            type: "availability",
          };
        }
      });
    },
    async disableLoanableModal() {
      const confirm = await this.$bvModal.msgBoxConfirm(
        "Êtes-vous sûr-e de vouloir retirer ce véhicule de la plateforme?",
        {
          size: "sm",
          buttonSize: "sm",
          okTitle: "Oui, retirer",
          cancelTitle: "Annuler",
          okVariant: "danger",
          cancelVariant: "ghost-secondary",
          footerClass: "p-2 border-top-0",
          centered: true,
        }
      );
      if (confirm) {
        await this.disableLoanable();
      }
    },
    async disableLoanable() {
      await this.$store.dispatch("loanables/disable", this.loanable.id);
      this.$store.dispatch("dashboard/loadLoanables");
    },
    async createIncident() {
      const { data } = await post(
        `/incidents`,
        {
          loanable_id: this.id,
          incident_type: "general",
          ...this.newIncident,
        },
        {
          notifications: { action: "création d'incident" },
        }
      );

      this.$router.push({ query: { "incident.id": data.id } });
      this.newIncident = {};
      this.loanable.active_incidents.push(data);
    },
    dayIsInThePast(day) {
      return dayjs().startOf("day").isAfter(day, "day");
    },
    dateIsInThePast(date) {
      return dayjs().isAfter(date, "minute");
    },
  },
});
</script>

<template>
  <layout-page name="loanable" fluid="lg" class="loanable-page">
    <layout-loading v-if="loading" />
    <div v-else-if="!loaded" class="text-center">
      <p>Erreur de chargement du véhicule. Il se peut que vous n'y ayez pas accès.</p>
      <icon-button variant="primary" icon="arrow-clockwise" :onclick="load">Réessayer</icon-button>
    </div>
    <div v-else>
      <b-row>
        <b-col md="4">
          <loanable-sidebar
            :loanable="loanable"
            :show-loanable-link="false"
            :show-admin-links="isAdminOfLoanable"
            :show-contacts-only="!isAdminOrCoowner"
            :show-instructions="isAdminOrCoowner"
            :show-all-instructions="isAdminOrCoowner"
          >
            <template #subtitle>
              <div class="mb-2">
                <availability-status :loanable="loanable" class="mb-2" />
                <icon-button
                  v-if="canEditLoanable"
                  size="sm"
                  role="edit"
                  :to="isAdminOfLoanable ? `/admin/loanables/${id}` : `/profile/loanables/${id}`"
                  >Modifier</icon-button
                >

                <icon-button
                  v-if="canDeleteLoanable"
                  class="ml-2"
                  size="sm"
                  variant="outline-danger"
                  role="remove-item"
                  :onclick="disableLoanableModal"
                >
                  Retirer
                </icon-button>
              </div>
            </template>
          </loanable-sidebar>
        </b-col>
        <b-col md="8" class="mt-3 mt-md-0">
          <template v-if="hasAnyBlockingActiveIncidents || filtersIncidentId">
            <incident-table
              :initial-filters="{ status: 'in_process' }"
              :loanable-id="id"
              :blocking-only="!isAdminOrCoowner && !filtersIncidentId"
              sync-filters-with-url
              url-prefix="incident"
            >
              <template v-if="canEditLoanable" #head-buttons>
                <icon-button
                  v-b-modal.new-incident-modal
                  icon="exclamation-triangle"
                  variant="outline-warning"
                  >Signaler un incident</icon-button
                >
              </template>
            </incident-table>
            <h2>Emprunts</h2>
          </template>
          <b-tabs pills>
            <b-tab title="Calendrier du véhicule">
              <loanable-calendar
                default-view="month"
                :events="events"
                month-day-detail
                :loading="loadingAvailabilities"
                availability-mode="available"
                @click.native.stop.prevent
                @ready="calendarChanged"
                @view-change="calendarChanged"
              ></loanable-calendar>
            </b-tab>
            <b-tab title="Liste des emprunts">
              <paginated-table
                id="profile-loanables-loans-table"
                ref="table"
                class="loans-table"
                :columns="loanColumns"
                :load-relations="['loanable.active_incidents', 'loanable.merged_user_roles.user']"
                endpoint="loans"
                :fetch-params="{ for: 'profile', loanable_id: id }"
                sync-filters-with-url
                :show-action-column="false"
                @row-clicked="(item) => $router.push(`/loans/${item.id}`)"
              >
                <template #cell(status)="{ item }">
                  <loan-status :item="item"></loan-status>
                </template>
              </paginated-table>
            </b-tab>
          </b-tabs>

          <incident-table
            v-if="!hasAnyBlockingActiveIncidents && isAdminOrCoowner && !filtersIncidentId"
            :loanable-id="id"
          >
            <template v-if="canEditLoanable" #head-buttons>
              <icon-button
                v-b-modal.new-incident-modal
                icon="exclamation-triangle"
                variant="outline-warning"
                >Signaler un incident</icon-button
              >
            </template>
          </incident-table>
        </b-col>
      </b-row>
    </div>
    <b-modal id="new-incident-modal" title="Signaler un incident" hide-footer>
      <template #default="{ close }">
        <validation-observer ref="observer" v-slot="{ passes }">
          <b-form :novalidate="true" class="form loan-actions-incident__form">
            <forms-validated-input
              v-model="newIncident.comments_on_incident"
              name="comments_on_incident"
              :rules="{ required: true }"
              label="Commentaire"
              type="textarea"
              :rows="3"
            />

            <forms-validated-input
              v-model="newIncident.blocking_until"
              type="datetime"
              name="blocking_until"
              label="Bloque les emprunts jusqu'au"
              :disabled-dates-fct="dayIsInThePast"
              :disabled-times-fct="dateIsInThePast"
            />
            <b-checkbox
              v-if="newIncident.blocking_until"
              v-model="newIncident.show_details_to_blocked_borrowers"
              class="mb-4"
              switch
            >
              Description et mises à jour visibles aux emprunteurs-ses affecté-es
            </b-checkbox>
            <b-alert
              v-if="newIncident.show_details_to_blocked_borrowers"
              variant="warning"
              show
              class="p-2"
            >
              <strong>Attention&nbsp;:</strong> assurez-vous qu'aucune information sensible
              (adresse, numéro de permis, etc.) n'est présente dans la description de l'incident ou
              dans les mises à jour.
            </b-alert>

            <div class="button-list justify-content-end">
              <icon-button
                role="cancel"
                :onclick="
                  () => {
                    close();
                    newIncident = {};
                  }
                "
              >
                Annuler
              </icon-button>
              <icon-button
                variant="success"
                :onclick="
                  async function () {
                    await passes(createIncident);
                    close();
                  }
                "
              >
                Créer
              </icon-button>
            </div>
          </b-form>
        </validation-observer>
      </template>
    </b-modal>
  </layout-page>
</template>

<style lang="scss">
@import "~bootstrap/scss/mixins/breakpoints";

.page.loanable {
  .page__content {
    padding-top: 1rem;
    padding-bottom: 1rem;
    @include media-breakpoint-up(md) {
      padding-top: 45px;
      padding-bottom: 45px;
    }
  }
  .loans-table {
    margin-top: -2rem;
  }
}
</style>
