<template>
  <layout-page :name="`community-view community-view--${view}`" fluid>
    <b-modal
      id="new-loan-modal"
      size="xl"
      centered
      hide-footer
      title="Nouvel emprunt"
      @shown="$store.commit('loanable.search/newLoanModalOpened', true)"
      @hidden="$store.commit('loanable.search/newLoanModalOpened', false)"
    >
      <full-loan loan-id="new" :tab="loanTab" class="p-0" />
    </b-modal>
    <div :class="`community-view__overlay ${isMap}`">
      <b-row>
        <b-col lg="4" xl="3">
          <!-- loan search form container -->
          <b-card class="community-view__search-container">
            <div ref="search-menu" class="community-view__search-menu">
              <search-form
                :possible-loanable-types="user.available_loanable_types"
                :selected-loanable-types="selectedLoanableTypes"
                :loading="loading"
                @selectLoanableTypes="selectLoanableTypes"
                @submit="searchLoanables"
              >
                <template #buttons>
                  <icon-button
                    v-if="view === 'list'"
                    variant="ghost-secondary"
                    pill
                    icon="map-fill"
                    @click="gotoView('map', true)"
                  >
                    Afficher la carte
                  </icon-button>
                  <icon-button
                    v-else
                    variant="ghost-secondary"
                    pill
                    icon="list"
                    @click="gotoView('list')"
                  >
                    Afficher la liste
                  </icon-button>
                </template>
              </search-form>
            </div>
          </b-card>
          <!---->
        </b-col>

        <!-- Results header -->
        <b-col lg="8" xl="9" class="mt-4">
          <!-- results display (loanable cards) -->
          <loanable-list
            v-if="view === 'list'"
            :data="loanables"
            :loading="loading"
            @select="
              (event) => {
                setLoanableInQuery(event.loanable);
                createLoan(event);
              }
            "
            @test="searchLoanables"
          />
          <!---->
        </b-col>
      </b-row>
    </div>
    <!-- map display -->
    <b-row v-show="view === 'map'">
      <loanable-map
        id="loanable-search-map"
        :loanables="loanables"
        :communities="userCommunities"
        :padding-client-width-min="992"
        :padding="{
          x: -150,
        }"
        :initially-selected-loanable-id="
          $route.query.loanable_id ? parseInt($route.query.loanable_id) : null
        "
        @test="searchLoanables"
        @select="createLoan"
        @select-loanable="onMapLoanableFocused"
      >
      </loanable-map>
    </b-row>
    <transition name="slide-up">
      <div v-if="showSummary" class="search-summary d-lg-none">
        <div class="d-flex flex-column justify-content-center">
          <div class="text-muted">Recherche</div>
          <div class="date-from">
            {{ searchDeparture | datetime }}
          </div>
        </div>
        <div class="button-list">
          <icon-button role="edit" @click="scrollToSearchForm" />
          <icon-button
            v-if="view === 'list'"
            icon="map"
            variant="ghost-secondary"
            @click="gotoView('map', true)"
          />
          <icon-button v-else icon="list" variant="ghost-secondary" @click="gotoView('list')" />
        </div>
      </div>
    </transition>
    <!---->
  </layout-page>
</template>
<script>
import FullLoan from "@/components/Loan/FullLoan.vue";
import SearchForm from "@/components/Loan/SearchForm.vue";

import LoanableList from "@/components/Loanable/List.vue";
import LoanableMap from "@/components/Loanable/Map.vue";
import IconButton from "@/components/shared/IconButton.vue";
import Authenticated, { mustBeAuthenticated } from "@/mixins/Authenticated";
import store from "@/store";
import { getBoundingBox } from "@/helpers/geoJson";

// 1 lat degree is ~111km, so 20m is 0.00018 degree
const MIN_LOANABLE_DISTANCE_FOR_MAP_VIEW = 0.00018;

const loanablesAreTooClose = (loanables) => {
  let { minLat, maxLat, minLng, maxLng } = getBoundingBox(loanables.map((l) => l.position_google));

  return (
    maxLat - minLat < MIN_LOANABLE_DISTANCE_FOR_MAP_VIEW ||
    maxLng - minLng < MIN_LOANABLE_DISTANCE_FOR_MAP_VIEW
  );
};

export default {
  name: "LoanableSearch",
  components: {
    IconButton,
    FullLoan,
    LoanableMap,
    LoanableList,
    SearchForm,
  },
  mixins: [Authenticated],
  beforeRouteEnter(to, from, next) {
    mustBeAuthenticated(to, from, () => {
      if (
        store.state.user.user_communities.filter((c) => !!c.approved_at && !c.suspended_at)
          .length === 0
      ) {
        store.commit("addNotification", {
          content:
            "Il faut avoir été accepté dans un quartier pour faire une recherche de véhicule",
          title: "En attente de validation",
          variant: "warning",
          type: "loan",
        });
        next("/app");
        return;
      }

      // when going to map, redirect to list if no loanables, loanables are loading or the bounding
      // box of loanable coordinates is < 20m
      if (to.params.view === "list") {
        next();
        return;
      }

      if (
        store.state["loanable.search"].loanablesLoading ||
        store.getters["loanable.search/loanables"].length < 1 ||
        loanablesAreTooClose(store.getters["loanable.search/loanables"])
      ) {
        next("/search/list");
        return;
      }
      next();
    });
  },
  props: {
    view: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      showSummary: false,
      summaryIntersectionObserver: null,
      loanTab: null,
    };
  },
  computed: {
    user() {
      return this.$store.state.user;
    },
    loanables() {
      return this.$store.getters["loanable.search/loanables"];
    },
    loading() {
      return this.$store.getters["loanable.search/loading"];
    },
    isMap() {
      return this.view === "map" ? "community-list--no-pointer-events" : "";
    },
    searchDeparture() {
      return this.$store.state["loanable.search"].searchDepartureAt;
    },
    selectedLoanableTypes() {
      return this.$store.state["loanable.search"].selectedLoanableTypes;
    },
    userCommunities() {
      return this.user.user_communities.map((c) => c.community);
    },
  },
  beforeDestroy() {
    if (!this.summaryIntersectionObserver) {
      return;
    }
    this.summaryIntersectionObserver.unobserve(this.$refs["search-menu"]);
  },
  async mounted() {
    this.setupObserver();
    let selectedTypes = [];
    if (this.$route.query.loanable_id) {
      this.scrollToMap();
    }
    const possibleTypes = this.user.available_loanable_types;
    const urlTypes = this.$route.query["types"];
    if (urlTypes) {
      selectedTypes = urlTypes.split(",").filter((urlType) => possibleTypes.includes(urlType));
    } else if (this.selectedLoanableTypes.length > 0) {
      selectedTypes = this.selectedLoanableTypes.filter((urlType) =>
        possibleTypes.includes(urlType)
      );
    } else {
      if (!this.user.borrower || !this.user.borrower.validated) {
        selectedTypes = possibleTypes.filter((t) => t !== "car");
      } else {
        selectedTypes = possibleTypes;
      }
    }
    const loadedLoanables = this.$store.state["loanable.search"].loanablesByType;
    const firstLoad = !loadedLoanables || Object.keys(loadedLoanables).length === 0;
    await this.updateSelectedTypes(selectedTypes);
    // If loanables weren't loaded previously, open map by default if loanables are too spread out
    if (
      firstLoad &&
      this.$route.params.view === "list" &&
      !loanablesAreTooClose(store.getters["loanable.search/loanables"])
    ) {
      this.gotoView("map");
    }
  },
  methods: {
    async gotoView(view, scrollToMap = false) {
      let query = "";
      if (this.$route.query.loanable_id) {
        query = `?loanable_id=${this.$route.query.loanable_id}`;
      }
      await this.$router.push(`/search/${view}${query}`);
      if (scrollToMap) {
        this.scrollToMap();
      }
    },
    scrollToMap(behavior = "instant") {
      if (this.view !== "map") {
        return;
      }
      if (window.innerWidth > 990) {
        // on large screens, we scroll to top
        window.scrollTo({ top: 0, behavior: "instant" });
        return;
      }
      let result = document.getElementById("loanable-search-map");
      setTimeout(() => {
        if (!result) {
          return;
        }
        window.scrollTo({
          top: window.scrollY + result.getBoundingClientRect().top + 1,
          behavior,
        });
      }, 50);
    },
    scrollToSearchForm() {
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
    setupObserver() {
      this.summaryIntersectionObserver = new IntersectionObserver((entries) => {
        if (entries.length > 0) {
          this.showSummary = !entries[0].isIntersecting;
        }
      });

      this.summaryIntersectionObserver.observe(this.$refs["search-menu"]);
    },
    selectLoanableTypes(value) {
      this.updateSelectedTypes(value.filter((item, i, ar) => ar.indexOf(item) === i));
    },
    async onMapLoanableFocused(loanable) {
      this.setLoanableInQuery(loanable);
      if (loanable && !loanable.tested) {
        await this.$store.dispatch("loanable.search/testOne", {
          loanable_id: loanable.id,
          borrower_user_id: this.$store.state.user.id,
        });
      }
    },
    setLoanableInQuery(loanable) {
      if (!loanable) {
        if (this.$route.query.loanable_id) {
          this.$router.replace({ query: { ...this.$route.query, loanable_id: undefined } });
        }
        return;
      }
      if (this.$route.query.loanable_id != loanable.id) {
        this.$router.replace({ query: { ...this.$route.query, loanable_id: loanable.id } });
      }
    },
    createLoan({ loanable, tab }) {
      // Save the loanable in the store to improve transition from search to new loan (avoid loading the loanable from the server)
      this.$store.commit("loanable.search/selectedLoanable", loanable);

      // For small screens, redirect to new loan page. For large screens, show the new loan modal
      if (window.innerWidth < 990) {
        let query = {
          loanable_id: loanable.id,
        };
        if (tab) {
          query.tab = tab;
        }
        this.$router.push({
          path: `/loans/new`,
          query,
        });
      } else {
        this.loanTab = tab;
        this.$bvModal.show("new-loan-modal");
      }
    },
    async searchLoanables() {
      await this.$store.dispatch("loanable.search/testAll");
      this.scrollToMap("smooth");
    },
    async updateSelectedTypes(selectedTypes) {
      const types = selectedTypes.join(",");

      if (this.$route.query.types !== types) {
        const query = {
          ...this.$route.query,
          types,
        };

        this.$router.replace({ ...this.$route, query });
      }
      await this.$store.dispatch("loanable.search/list", { types: selectedTypes });
    },
  },
};
</script>

<style lang="scss">
@import "~bootstrap/scss/mixins/breakpoints";

.community-view {
  &__overlay {
    z-index: 20;
    position: relative;
    button {
      pointer-events: all;
    }
  }

  &__button-modify-search {
    color: $primary !important;
    cursor: pointer;

    &:hover {
      color: $primary;
    }
  }

  &__button-spacing {
    display: flex;
    flex-direction: row;
  }

  &__button-spacing > div {
    display: flex;
    align-items: center;
    margin: 0 5px;
  }

  // Go to map & list buttons
  .btn-secondary {
    color: #7a7a7a;
    background: #fff;
    border: 1px solid #e5e5e5;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    box-sizing: border-box;
    margin: 0;

    &:hover {
      color: #7a7a7a;
      background: #fff;
      border: 1px solid #e5e5e5;
    }
  }

  &__search-container {
    margin-top: 1.5rem;
    pointer-events: all;
    /* Allow calendar to be visible when overflowing the card.
     Making this visible, improves the UI for the calendar, but fails to show a scrollbar when
     necessary for smaller screens*/
    overflow: auto;
  }

  h3 {
    font-weight: 700;

    @include media-breakpoint-down(md) {
      line-height: $h4-line-height;
      font-size: $h4-font-size;
    }
  }

  .community-map {
    height: calc(100vh - 4rem);
    width: 100%;
    @include media-breakpoint-up(lg) {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: calc(100vh - #{$layout-navbar-height});
      z-index: 10;
    }
  }
}

.community-view--map {
  .page__content {
    position: relative;
  }
}

.community-view__search-container {
  box-shadow: $medium-shadow;
  @include media-breakpoint-up(lg) {
    max-height: calc(100vh - #{$layout-navbar-height} - 3rem);
  }
}

.community-list--no-pointer-events {
  pointer-events: none;
}

.community-view--margin-top {
  margin-top: 20px;
}

.search-summary {
  position: fixed;
  bottom: 0;
  width: 100%;
  background: $main-bg;
  // Slightly darker than $shadow-color since it applies on more than just the regular background
  $shadow: #5a646c;
  box-shadow:
    0px -1px 1px change-color($shadow, $alpha: 0.5),
    0px -4px 4px change-color($shadow, $alpha: 0.3),
    0px -10px 10px -2px change-color($shadow, $alpha: 0.4),
    0px -40px 40px -3px change-color($shadow, $alpha: 0.3);
  height: 4rem;
  left: 0;
  z-index: 1200;
  padding: 0.5rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  .date-from {
    display: flex;
    gap: 0.5rem;
    font-size: 1.125rem;
    font-weight: 600;
  }
}
.slide-up-enter,
.slide-up-leave-to {
  transform: translateY(4rem);
}

.slide-up-enter-active,
.slide-up-leave-active {
  transition: transform 0.2s ease;
}
</style>
