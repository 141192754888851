import ProfilePage from "@/views/Profile.vue";
import ProfileAccount from "@/views/profile/Account.vue";
import ProfileBorrower from "@/views/profile/Borrower.vue";
import ProfileCommunities from "@/views/profile/Communities.vue";
import ProfileCommunity from "@/views/profile/Community.vue";
import ProfileInvoice from "@/views/profile/Invoice.vue";
import ProfileInvoices from "@/views/profile/Invoices.vue";
import ProfileLibrary from "@/views/profile/Library.vue";
import ProfileLoanable from "@/views/profile/LoanableForm.vue";
import ProfileLoanables from "@/views/profile/Loanables.vue";
import ProfileLoans from "@/views/profile/Loans.vue";
import ProfileLocomotion from "@/views/profile/Locomotion.vue";
import ProfilePaymentMethod from "@/views/profile/PaymentMethod.vue";
import ProfilePaymentMethods from "@/views/profile/PaymentMethods.vue";
import ProfileReports from "@/views/profile/Reports.vue";

export default [
  {
    path: "/profile",
    name: "profile",
    component: ProfilePage,
    meta: {
      auth: true,
      title: "titles.profile",
    },
    children: [
      {
        path: "account",
        component: ProfileAccount,
        meta: {
          auth: true,
          title: "titles.account",
          slug: "users",
        },
      },
      {
        path: "locomotion",
        component: ProfileLocomotion,
        meta: {
          auth: true,
          title: "titles.profile",
          slug: "users",
        },
      },
      {
        path: "borrower",
        component: ProfileBorrower,
        meta: {
          auth: true,
          title: "titles.borrower",
          slug: "users",
        },
      },
      {
        path: "communities",
        component: ProfileCommunities,
        meta: {
          auth: true,
          title: "profile.titles.communities",
        },
      },
      {
        path: "communities/:id",
        component: ProfileCommunity,
        props: true,
        meta: {
          auth: true,
          title: "profile.titles.community",
        },
      },
      {
        path: "invoices",
        component: ProfileInvoices,
        meta: {
          auth: true,
          title: "titles.invoices",
        },
      },
      {
        path: "invoices/:id",
        component: ProfileInvoice,
        props: true,
        meta: {
          auth: true,
          title: "titles.invoice",
          slug: "invoices",
          params: {
            relations: "user",
          },
        },
      },
      {
        path: "loanables",
        component: ProfileLoanables,
        meta: {
          auth: true,
          title: "titles.loanables",
          slug: "loanables",
        },
      },
      {
        path: "loanables/:id",
        component: ProfileLoanable,
        props: true,
        meta: {
          auth: true,
          slug: "loanables",
          title: "titles.loanable",
        },
      },
      {
        path: "libraries/:id",
        component: ProfileLibrary,
        props: true,
        meta: {
          auth: true,
          title: "titles.library",
        },
      },
      {
        path: "loans",
        component: ProfileLoans,
        meta: {
          auth: true,
          title: "titles.loans",
          slug: "loans",
        },
      },
      {
        path: "locomotion",
        component: ProfileLocomotion,
        meta: {
          auth: true,
          title: "titles.profile",
          slug: "users",
        },
      },
      {
        path: "payment_methods",
        component: ProfilePaymentMethods,
        meta: {
          auth: true,
          title: "titles.payment_methods",
          slug: "paymentMethods",
        },
      },
      {
        path: "payment_methods/:id",
        component: ProfilePaymentMethod,
        props: true,
        meta: {
          auth: true,
          slug: "paymentMethods",
          title: "titles.payment_method",
        },
      },
      {
        path: "reports",
        component: ProfileReports,
        props: true,
        meta: {
          auth: true,
          title: "titles.reports",
        },
      },
    ],
  },
];
