import { isAdminOfSomeCommunity } from "@/helpers/permissions/communities";
import { isGlobalAdmin } from "@/helpers/permissions/users";
import { mustBeAuthenticated } from "@/mixins/Authenticated";
import store from "@/store";
import AdminPage from "@/views/Admin.vue";
import AdminMailingLists from "@/views/admin/AdminMailingLists.vue";
import AdminReports from "@/views/admin/AdminReports.vue";
import AdminCommunities from "@/views/admin/Communities.vue";
import AdminCommunity from "@/views/admin/Community.vue";
import AdminDashboard from "@/views/admin/Dashboard.vue";
import GbfsDataset from "@/views/admin/GbfsDataset.vue";
import GbfsDatasets from "@/views/admin/GbfsDatasets.vue";
import AdminInvoice from "@/views/admin/Invoice.vue";
import AdminInvoices from "@/views/admin/Invoices.vue";
import AdminLoan from "@/views/admin/Loan.vue";
import AdminLoanable from "@/views/admin/Loanable.vue";
import AdminLoanables from "@/views/admin/Loanables.vue";
import AdminLibraries from "@/views/admin/Libraries.vue";
import AdminLibrary from "@/views/admin/Library.vue";
import AdminLoans from "@/views/admin/Loans.vue";
import AdminPayouts from "@/views/admin/Payouts.vue";
import AdminPricings from "@/views/admin/Pricings.vue";
import AdminUser from "@/views/admin/User.vue";
import AdminUsers from "@/views/admin/Users.vue";
import CommunityInvitation from "../views/admin/Invitation.vue";
import AdminInvitations from "../views/admin/Invitations.vue";

function mustBeGlobalAdmin(to, from, next) {
  if (isGlobalAdmin(store.state.user)) {
    return next();
  }
  next("/admin/dashboard");
}

function mustBeAdminOfSomeCommunity(to, from, next) {
  if (!store.state.user) {
    return next("/login");
  }

  if (isGlobalAdmin(store.state.user) || isAdminOfSomeCommunity(store.state.user)) {
    return next();
  }
  next("/app");
}

// Executes a stack of guards, stopping if any return a non undefined value
function checkGuardsStack(guards, to, from, next) {
  const nextGuard = guards.shift();

  // No more guards
  if (nextGuard === undefined) {
    next();
    return;
  }

  nextGuard(to, from, (nextArg) => {
    // Previous guard did no redirect or cancel, we check following guards
    if (nextArg === undefined) {
      checkGuardsStack(guards, to, from, next);
      return;
    }

    next(nextArg);
  });
}
function guards(guards) {
  return (to, from, next) => {
    return checkGuardsStack(guards, to, from, next);
  };
}

export default [
  {
    path: "/admin",
    component: AdminPage,
    beforeEnter: guards([
      mustBeAuthenticated,
      mustBeAdminOfSomeCommunity,
      (to, from, next) => {
        if (to.path === "/admin") {
          next("admin/dashboard");
          return;
        }
        next();
      },
    ]),
    meta: {
      auth: true,
      title: "titles.admin",
    },
    children: [
      {
        path: "dashboard",
        component: AdminDashboard,
        beforeEnter: guards([mustBeAuthenticated, mustBeAdminOfSomeCommunity]),
        meta: {
          auth: true,
          title: "titles.dashboard",
        },
      },
      {
        path: "communities",
        component: AdminCommunities,
        beforeEnter: guards([mustBeAuthenticated, mustBeAdminOfSomeCommunity]),
        meta: {
          auth: true,
          title: "titles.communities",
        },
      },
      {
        path: "communities/:id",
        component: AdminCommunity,
        beforeEnter: guards([mustBeAuthenticated, mustBeAdminOfSomeCommunity]),
        props: true,
        meta: {
          auth: true,
          slug: "communities",
          title: "titles.community",
          data: {
            loanableTypes: {
              loadTypes: {},
            },
          },
        },
      },

      {
        path: "libraries",
        component: AdminLibraries,
        beforeEnter: guards([mustBeAuthenticated, mustBeAdminOfSomeCommunity]),
        meta: {
          auth: true,
          title: "titles.libraries",
        },
      },

      {
        path: "libraries/:id",
        component: AdminLibrary,
        beforeEnter: guards([mustBeAuthenticated, mustBeAdminOfSomeCommunity]),
        props: true,
        meta: {
          auth: true,
          title: "titles.libraries",
        },
      },
      {
        path: "loanables",
        component: AdminLoanables,
        beforeEnter: guards([mustBeAuthenticated, mustBeAdminOfSomeCommunity]),
        meta: {
          auth: true,
          title: "titles.loanables",
        },
      },
      {
        path: "loanables/:id",
        component: AdminLoanable,
        beforeEnter: guards([mustBeAuthenticated, mustBeAdminOfSomeCommunity]),
        props: true,
        meta: {
          auth: true,
          slug: "loanables",
          params: {
            with_deleted: true,
          },
          title: "titles.loanable",
        },
      },
      {
        path: "users",
        component: AdminUsers,
        beforeEnter: guards([mustBeAuthenticated, mustBeAdminOfSomeCommunity]),
        meta: {
          auth: true,
          title: "titles.users",
        },
      },
      {
        path: "users/:id",
        component: AdminUser,
        beforeEnter: guards([mustBeAuthenticated, mustBeAdminOfSomeCommunity]),
        props: true,
        meta: {
          auth: true,
          slug: "users",
          title: "titles.user",
        },
      },
      {
        path: "invoices",
        component: AdminInvoices,
        beforeEnter: guards([mustBeAuthenticated, mustBeGlobalAdmin]),
        meta: {
          auth: true,
          title: "titles.invoices",
        },
      },
      {
        path: "invoices/:id",
        component: AdminInvoice,
        beforeEnter: guards([mustBeAuthenticated, mustBeGlobalAdmin]),
        props: true,
        meta: {
          auth: true,
          slug: "invoices",
          params: {
            relations: "user",
          },
          title: "titles.invoice",
          data: {
            users: {
              retrieveOne: {
                conditional({ route }) {
                  return !!route && !!route.query && !!route.query.user_id;
                },
                id({ route: { query } }) {
                  return query.user_id;
                },
              },
            },
          },
        },
      },
      {
        path: "loans",
        component: AdminLoans,
        beforeEnter: guards([mustBeAuthenticated, mustBeAdminOfSomeCommunity]),
        meta: {
          auth: true,
          title: "titles.loans",
        },
      },
      {
        path: "loans/:id",
        component: AdminLoan,
        beforeEnter: guards([mustBeAuthenticated, mustBeAdminOfSomeCommunity]),
        props: true,
        meta: {
          auth: true,
          slug: "loans",
          title: "titles.loan",
        },
      },
      {
        path: "pricings",
        component: AdminPricings,
        beforeEnter: guards([mustBeAuthenticated, mustBeGlobalAdmin]),
        meta: {
          auth: true,
          title: "titles.pricings",
        },
      },
      {
        path: "invitations",
        component: AdminInvitations,
        beforeEnter: guards([mustBeAuthenticated, mustBeAdminOfSomeCommunity]),
        meta: {
          auth: true,
          title: "titles.invitations",
        },
      },
      {
        path: "invitations/new",
        component: CommunityInvitation,
        beforeEnter: guards([mustBeAuthenticated, mustBeAdminOfSomeCommunity]),
        meta: {
          auth: true,
          title: "titles.invitations",
        },
      },
      {
        path: "gbfs_datasets",
        component: GbfsDatasets,
        beforeEnter: guards([mustBeAuthenticated, mustBeGlobalAdmin]),
        meta: {
          auth: true,
          title: "titles.gbfs",
        },
      },
      {
        path: "gbfs_datasets/:name",
        component: GbfsDataset,
        beforeEnter: guards([mustBeAuthenticated, mustBeGlobalAdmin]),
        props: true,
        meta: {
          auth: true,
          title: "titles.gbfs",
        },
      },
      {
        path: "reports",
        component: AdminReports,
        beforeEnter: guards([mustBeAuthenticated, mustBeGlobalAdmin]),
        meta: {
          auth: true,
          title: "titles.reports",
        },
      },
      {
        path: "payouts",
        component: AdminPayouts,
        beforeEnter: guards([mustBeAuthenticated, mustBeGlobalAdmin]),
        meta: {
          auth: true,
          title: "titles.payouts",
        },
      },
      {
        path: "mailinglists",
        component: AdminMailingLists,
        beforeEnter: guards([mustBeAuthenticated, mustBeGlobalAdmin]),
        meta: {
          auth: true,
          title: "titles.mailinglists",
        },
      },
    ],
  },
];
