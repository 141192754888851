import { del, post, put } from "@/requests/server";
import RestModule from "../RestModule";

export default new RestModule(
  "users",
  {
    form: {
      general: {
        email: {
          type: "email",
          rules: {
            email: true,
          },
        },
        name: {
          type: "text",
          rules: {
            required: true,
          },
        },
        last_name: {
          type: "text",
          rules: {
            required: true,
          },
        },
        avatar: {
          type: "image",
          aspect_ratio: "1 / 1",
        },
        description: {
          type: "textarea",
        },
        date_of_birth: {
          type: "date",
          rules: {
            date: true,
            before: "18 years ago",
          },
        },
        address: {
          type: "text",
          rules: {
            required: true,
          },
        },
        postal_code: {
          type: "text",
        },
        phone: {
          type: "text",
          rules: {
            required: true,
          },
        },
        is_smart_phone: {
          type: "checkbox",
          rules: {
            boolean: true,
          },
        },
        other_phone: {
          type: "text",
        },
        accept_conditions: {
          type: "checkbox",
          rules: {
            accepted: true,
          },
        },
        bank_transit_number: {
          type: "text",
        },
        bank_institution_number: {
          type: "text",
        },
        bank_account_number: {
          type: "text",
        },
      },
      borrower: {
        drivers_license_number: {
          type: "text",
        },
        has_not_been_sued_last_ten_years: {
          type: "checkbox",
          rules: {
            boolean: true,
          },
        },
        gaa: {
          type: "files",
        },
        saaq: {
          type: "files",
        },
      },
    },
    empty: {
      name: "",
      borrower: {},
      accept_conditions: false,
    },
  },
  {
    async approveBorrower({ commit }, userId) {
      const { data } = await put(`/users/${userId}/borrower/approve`, null, {
        requestOptions: { cancelId: `approve-borrower-${userId}` },
        notifications: {
          action: "approbation du dossier de conduite",
          onSuccess: "Dossier de conduite approuvé!",
        },
      });

      commit("mergeItem", { borrower: data });
    },
    async suspendBorrower({ commit }, userId) {
      const { data } = await put(`/users/${userId}/borrower/suspend`, null, {
        requestOptions: { cancelId: `suspend-borrower-${userId}` },
        notifications: {
          action: "suspension du dossier de conduite",
          onSuccess: "Dossier de conduite suspendu!",
        },
      });

      commit("mergeItem", { borrower: data });
    },
    async unsuspendBorrower({ commit }, userId) {
      const { data } = await del(`/users/${userId}/borrower/suspend`, {
        requestOptions: { cancelId: `unsuspend-borrower-${userId}` },
        notifications: { action: "rétablissement du dossier de conduite" },
      });

      commit("mergeItem", { borrower: data });
    },
    async resetBorrower({ commit }, userId) {
      const { data } = await put(`/users/${userId}/borrower/reset`, null, {
        requestOptions: { cancelId: `reset-borrower-${userId}` },
        notifications: {
          action: "réinitialisation du statut du dossier de conduite",
          onSuccess: "Statut du dossier de conduite réinitalisé!",
        },
      });

      commit("mergeItem", { borrower: data });
    },
    async updatePassword({ commit }, { userId, currentPassword, newPassword }) {
      // request to update password
      try {
        await post(
          `/users/${userId}/password`,
          {
            current: currentPassword,
            new: newPassword,
          },
          {
            requestOptions: { cancelId: "update-password", expects: [401] },
            notifications: { action: "changement du mot de passe" },
          }
        );
        return true;
      } catch (e) {
        if (e.response?.status === 401) {
          commit(
            "addNotification",
            {
              content: "Le mot de passe entré ne correspond pas à votre mot de passe actuel.",
              title: "Mot de passe invalide",
              variant: "danger",
            },
            { root: true }
          );

          return false;
        }
        throw e;
      }
    },
    async submitBorrower({ commit, state, rootState }) {
      commit("loaded", false);
      commit("loading", true);

      const { data: borrower } = await put(
        `/users/${state.item.id}/borrower/submit`,
        state.item.borrower,
        {
          requestOptions: {
            cancelId: "submit-borrower",
          },
          notifications: { action: "soumission du dossier de conduite" },
          cleanupCallback: () => commit("loading", false),
        }
      );

      if (rootState.user.id === state.item.id) {
        commit("mergeUser", { borrower }, { root: true });
      }
      commit("patchItem", { borrower });
      commit("patchInitialItem", { borrower });
      commit("loaded", true);
    },
    async update({ commit, state, rootState }, { id, data, params, showToastOnSuccess = true }) {
      commit("loaded", false);
      commit("loading", true);

      const { data: item } = await put(`/${state.slug}/${id}`, data, {
        axiosRequestConfig: { params },
        requestOptions: { cancelId: `update-user-${id}` },
        notifications: {
          action: "changement",
          onSuccess: showToastOnSuccess ? "changements sauvegardés!" : false,
        },
        cleanupCallback: () => commit("loading", false),
      });

      // If the user currently being updated is the logged-in user
      // (rootState.user), then update it's state as well.
      // Only diff with the update method in RestModule.
      if (rootState.user.id === item.id) {
        commit("mergeUser", { ...item }, { root: true });
      }
      commit("item", item);
      commit("initialItem", item);
      commit("loaded", true);
    },
  }
);
